<template>
  <v-container fill-height fluid>

    <!--title + add button-->
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <h2 class="mt-4 grey--text text--darken-1">Global notes</h2>
      </v-col>
      <v-col cols="auto" offset="2">
        <v-btn @click="dialogInsert = true" class="mt-6" color="amber darken-4" outlined small>
          <v-icon left>
            mdi-format-list-group-plus
          </v-icon>
          Add note
        </v-btn>
      </v-col>
    </v-row>

    <!--date pickers-->
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-menu v-model="fromDatePickerMenuToggle" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fromDatePicker" label="From date" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" style="width: 200px"/>
          </template>
          <v-date-picker v-model="fromDatePicker" @input="fromDatePickerMenuToggle = false; loadNotesByDate()"/>
        </v-menu>
      </v-col>
      <v-col cols="auto" offset-lg="1" offset-xl="1" offset-md="1" offset-sm="1">
        <v-menu v-model="toDatePickerMenuToggle" :close-on-content-click="false" :nudge-right="40"
                offset-y transition="scale-transition" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="toDatePicker" label="To date" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" style="width: 200px"/>
          </template>
          <v-date-picker v-model="toDatePicker" @input="toDatePickerMenuToggle = false; loadNotesByDate()"/>
        </v-menu>
      </v-col>
    </v-row>

    <!--insert/add note dialog-->
    <v-dialog v-model="dialogInsert" max-width="500px">
      <v-card>
        <v-card-title primary-title>
          <div class="mt-1">
            Insert new note
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-textarea v-model="newDialogText" background-color="amber lighten-5" auto-grow rows="5"
                            color="orange orange-darken-4" append-icon="mdi-comment" label="Note"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="grey darken-1" class="mr-6 mb-4" outlined @click="dialogInsert = false">Cancel</v-btn>
          <v-btn color="primary" class="mb-4" outlined @click="saveNote">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--if any error happened-->
    <v-row v-if="errorMessage.length > 0">
      <v-col>
        <v-alert :value="true" type="error">{{ errorMessage }}</v-alert>
      </v-col>
    </v-row>

    <!--notes-->
    <v-row class="mt-5" justify="center">
      <v-col cols="12">
        <v-card v-for="note in notes" :key="note.id" class="mx-auto my-5" max-width="600" outlined elevation="0">
          <v-card-text>
            <v-row align="center" style="margin-top: -1rem; margin-bottom: -1.5rem;">
              <v-col cols="4"><span>{{ note.username }}</span></v-col>
              <v-spacer></v-spacer>
              <v-col cols="4"><span style="font-size: 0.8rem" class="text--disabled">{{ note.time }}</span></v-col>
            </v-row>
            <v-row dense style="margin-top: -1.2rem; margin-bottom: -1.5rem">
              <v-col>
                <v-textarea v-model="note.text" background-color="amber lighten-5" auto-grow rows="2"
                            color="orange orange-darken-4" append-icon="mdi-comment" readonly/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>

  </v-container>
</template>

<script>
import {BackendError} from "@/exceptions/BackendError";
import NoteService from "@/service/NoteService";
import {PeriodDTO} from "@/model/PeriodDTO";
import moment from "moment";

export default {
  name: "NoteComponent",
  data: () => ({
    //dialogs
    dialogDelete: false,
    dialogInsert: false,
    newDialogText: '',
    showOverlay: true,

    //date pickers
    fromDatePickerMenuToggle: '',
    toDatePickerMenuToggle: '',
    fromDatePicker: '',
    toDatePicker: '',

    notes: [],
    errorMessage: ''
  }),

  created() {
    //load initial notes
    this.loadMonthlyNotes();
  },

  methods: {
    async loadMonthlyNotes() {
      let apiRes;
      try {
        apiRes = await NoteService.getByPeriod(new PeriodDTO(
            moment().startOf('month').format('yy-MM-DD'),
            moment().format('yy-MM-DD')));
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      } finally {
        this.showOverlay = false;
      }
      if (apiRes === undefined) {
        return;
      }
      if (apiRes.length === 0) {
        this.errorMessage = "No notes found!";
        return;
      }
      this.errorMessage = "";
      this.notes = apiRes;
    },

    async loadNotesByDate() {
      if (this.fromDatePicker === '' || this.toDatePicker === '') {
        return;
      }
      this.errorMessage = '';
      this.showOverlay = true;
      let apiResponse;
      try {
        apiResponse = await NoteService.getByPeriod(new PeriodDTO(this.fromDatePicker, this.toDatePicker));
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      } finally {
        this.showOverlay = false;
      }
      this.notes = apiResponse;
    },

    /**
     * save note, clear list, clear date pickers, show only added note
     * @returns {Promise<void>}
     */
    async saveNote() {
      let apiRes;
      try {
        apiRes = await NoteService.add(this.newDialogText);
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      }
      this.dialogInsert = false;
      this.fromDatePicker = '';
      this.toDatePicker = '';
      this.notes = [];
      this.notes.push(apiRes);
      this.errorMessage = '';
    }

  }

}
</script>