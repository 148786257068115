import axios from 'axios';
import {BackendError} from "@/exceptions/BackendError";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL + 'notes';

class NoteService {

    currentToken() {
        return localStorage.getItem('token');
    }

    getByPeriod(periodDTO) {
        return axios.post(API_BASE_URL + '/period', periodDTO,
            {
                headers: {
                    Authorization: 'Bearer ' + this.currentToken()
                }
            }).then(response => response.data)
            .catch((ex) => {
                console.log("exp happened")
                console.log(ex)
                if (ex.response) {
                    if (ex.response.data) {
                        throw new BackendError(ex.response.data.errorMessage);
                    }
                }
                throw new BackendError("Problem during request handling!");
            });
    }

    add(text) {
        return axios.post(API_BASE_URL + '/', JSON.stringify({"text": text}),
            {
                headers: {
                    Authorization: 'Bearer ' + this.currentToken(),
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(response => response.data)
            .catch((ex) => {
                if (ex.response) {
                    if (ex.response.data) {
                        throw new BackendError(ex.response.data.errorMessage);
                    }
                }
                throw new BackendError("Problem during request handling!");
            });
    }

}

export default new NoteService();